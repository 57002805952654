import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

enum Params {
  entertainmentCenterId = 'entertainmentCenterId',
  roomId = 'roomId',
  tableId = 'tableId',
}

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const AppPage = () => {
  const query = useQuery()

  const openApp = () =>
    window.open(
      `oks://app/${query.get(Params.entertainmentCenterId) || ''}/${query.get(Params.roomId) || ''}/${
        query.get(Params.tableId) || ''
      }`,
      // `oks://app?entertainmentCenterId=${query.get(Params.entertainmentCenterId) || ''}&roomId=${
      //   query.get(Params.roomId) || ''
      // }&tableId=${query.get(Params.tableId) || ''}`,
    )

  const goGooglePlay = () => window.location.replace('https://play.google.com/store/apps')
  const goAppStore = () => window.location.replace('https://www.apple.com/ru/app-store/')

  const isAndroid = /Android/i.test(navigator.userAgent)
  const isIos = /iPhone/i.test(navigator.userAgent)

  return (
    <Container>
      {/*<Text>entertainmentCenterId: {query.get(Params.entertainmentCenterId) || 'no data'}</Text>*/}
      {/*<Text>roomId: {query.get(Params.roomId) || 'no data'}</Text>*/}
      {/*<Text>tableId: {query.get(Params.tableId) || 'no data'}</Text>*/}
      {/*<Text>userAgent: {window.navigator.userAgent || 'no data'}</Text>*/}
      {(isIos || isAndroid) && <Btn onClick={openApp}>Перейти к приложению</Btn>}
      {!isIos && <Btn onClick={goGooglePlay}>Перейти в Google Play</Btn>}
      {!isAndroid && <Btn onClick={goAppStore}>Перейти в App Store</Btn>}
    </Container>
  )
}

export default AppPage

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
// const Text = styled.span`
//   &:not(:last-child) {
//     margin-bottom: 16px;
//   }
// `
const Btn = styled.div`
  cursor: pointer;
  display: inline-block;
  border: 1px solid #e1e9f0;
  border-radius: 4px;
  padding: 4px 8px;
  margin: 16px 4px;
  &:hover {
    box-shadow: 0 2px 5px 2px rgba(34, 60, 80, 0.2);
  }
`
