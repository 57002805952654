import { TState } from 'store/store'

export const getXlsFileID = (state: TState) => state.file.xlsFileID
export const getXlsFileOriginalName = (state: TState) => state.file.xlsFileOriginalName
export const getShowParsingWindow = (state: TState) => state.file.showParsingWindow
export const getFileUploading = (state: TState) => state.file.fileUpLoading
export const getXlsFileParsing = (state: TState) => state.file.xlsFileParsing
export const getKaraokeFilesUploading = (state: TState) => state.file.karaokeFilesUploading
export const getKaraokeFilesCount = (state: TState) => state.file.karaokeFilesCount
export const getKaraokeFilesTotalCount = (state: TState) => state.file.karaokeFilesTotalCount
export const getXlsFileParsingError = (state: TState) => state.file.xlsFileParsingError
