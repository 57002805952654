export type TPhonogramsState = {
  rowIndex: number
  firstUpdate: boolean
  phonogramID: string | null
  phonogramsList: Array<TPhonogram>
  phonogramsListHasMore: boolean
  phonogramsListForPlaylists: Array<TPhonogram>
  phonogramsListHasMoreForPlaylists: boolean
  phonogramByID: TPhonogram | null
  phonogramByIDIsLoad: boolean
  editPhonogramByIDSuccess: boolean
  createPhonogramSuccess: boolean
  phonogramsLoadOffset: number
  phonogramsLoadOffsetForPlaylists: number
  showValidateFileNameWindow: boolean
  nextCatalogPhonogramNumber: number | null
  genresList: TGenreItem[]
  navbarWidth: number
  buildingKaraokeFile: boolean
  buildingKaraokeFileId: string | null
  tempLinkBuildKaraokeFile: string | null
  karaokeFileIsBuild: boolean
  fileDeleting: boolean
  fileDeletingSuccess: boolean
  phonogramStatistics: TPhonogramStatistics | null
  genreSortParams: TFilterByGenresValue
  searchParams: string
  deletedPhonogramsFilterValue: TFilterValue
  approvedFilterValue: TFilterValue
  hasMusicFileFilterValue: TFilterValue
  hasLyricsFileFilterValue: TFilterValue
  draftFilterValue: TFilterValue
  orderByColumn: string
  orderBy: ESort
  runFilters: boolean
}

export type TSelectIsExplicit = {
  label: string
  value: boolean
}

export type TSelectGenre = {
  label: string
  value: string
}

export type TFile = {
  createdAt: string
  id: number
  uploadedBy: number
  relativePath: string
  mimeType: string
  size: number
  originalName: string
  updatedAt: string
  deletedAt: string | null
}

export type TMusicFile = {
  createdAt: string
  updatedAt: string
  deletedAt: string
  id: string
  catalogPhonogramNumber: number
  file: TFile
  streamOrder: string
}

export type TLyricsFile = {
  createdAt: string
  updatedAt: string
  deletedAt: string
  id: string
  lyrics: string
  file: TFile
}

export type TPhonogram = {
  id?: string | null
  isrc: string
  catalogPhonogramNumber?: number
  genre?: TGenreItem | null
  genreId?: string
  name: string
  nameTr: string
  copyright: string
  copyrightTr: string
  singerName: string
  singerTrName: string
  album: string
  albumTr: string
  authorName: string
  authorTrName: string
  authorTextName: string
  authorTextTrName: string
  isExplicit: boolean | TSelectIsExplicit
  isDraft: boolean
  isApproved: boolean
  isBackground: boolean
  musicFile?: TMusicFile
  musicFileId?: string
  musicLyrics?: TLyricsFile
  musicLyricsId?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadKaraokeFile?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadSubTitlesFile?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  imgFile?: any
  createdAt?: string
  deletedAt?: string | null
  updatedAt?: string
  numberOfListening?: number
  phonogramId?: string
  coverImageId?: null | string
  coverImage?: {
    id: string
    relativePath: string
  } | null
  inBlacklist?: boolean
  listeningCount?: number
}

export type TGenreItem = {
  id: string | undefined
  name: string
  nameTr: string
  value?: string
  label?: string
}

export type TFilterValue = {
  value: boolean | undefined
  label: string
}

export type TFilterByGenresValue = {
  value: string | undefined
  label: string
}

export type TPhonogramStatistics = {
  all: number
  daily: number
  monthly: number
  yearly: number
}

export enum ESort {
  ASC = 'ASC',
  DESC = 'DESC',
}
