import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { getXlsFileOriginalName, getXlsFileParsing, getXlsFileParsingError } from 'store/modules/file/selectors'
import { setShowParsingWindow, setXlsFileParsingError } from 'store/modules/file/reducer'
import { colors } from 'styles/colors'

import closeBtnIcon from 'assets/icons/iconClose.svg'

const useStyles = createUseStyles({
  wrapper: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(33,100,169,0.2)',
    zIndex: 10,
  },
  window: {
    minWidth: 600,
    borderRadius: 4,
    boxShadow: [[0, 0, 10, 0, 'rgba(0,0,0,0.5)']],
    backgroundColor: colors.white,
    padding: 24,
  },
  windowInner: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    color: colors.graySecond,
    margin: {
      top: 0,
      right: 'auto',
      bottom: 0,
      left: 0,
    },
  },
  closeBtn: {
    width: 16,
    height: 16,
    border: 'none',
    backgroundImage: `url(${closeBtnIcon})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  textWrapper: {
    marginTop: 20,
    marginBottom: 20,
  },
  text: {
    display: 'block',
    color: colors.graySecond,
    fontSize: 18,
    marginBottom: 10,
  },
  textError: {
    display: 'block',
    color: colors.redMain,
    fontSize: 18,
    marginBottom: 10,
  },
  fileName: {
    color: colors.blueMain,
  },
  okBtn: {
    fontSize: 16,
    fontWeight: 700,
    backgroundColor: 'transparent',
    color: colors.blueMain,
    border: 'none',
    padding: 5,
    cursor: 'pointer',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})

export const PopUpParsing: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const parsingXlsFile = useSelector(getXlsFileParsing)
  const originalName = useSelector(getXlsFileOriginalName)
  const error = useSelector(getXlsFileParsingError)

  const closeWindow = () => {
    dispatch(setShowParsingWindow(false))
    dispatch(setXlsFileParsingError(null))
  }

  return (
    <div className={classes.wrapper}>
      <section className={classes.window}>
        <div className={classes.windowInner}>
          <h1 className={classes.title}>Сообщение</h1>
          <div onClick={closeWindow} className={classes.closeBtn} />
        </div>
        <div className={classes.textWrapper}>
          {parsingXlsFile && (
            <span className={classes.text}>
              Файл
              <span className={classes.fileName}> {originalName} </span>
              успешно загружен.
            </span>
          )}

          {!parsingXlsFile && !error && (
            <span className={classes.text}>
              Файл
              <span className={classes.fileName}> {originalName} </span>
              успешно обработан.
            </span>
          )}

          {error && (
            <span className={classes.textError}>
              Файл
              <span className={classes.fileName}> {originalName} </span>
              не обработан.
            </span>
          )}

          {parsingXlsFile && <span className={classes.text}>Идёт процесс обработки файла.</span>}
          {parsingXlsFile && (
            <span className={classes.text}>Во время обработки файла загрузка новых файлов ограничена!</span>
          )}
        </div>
        <div className={classes.windowInner}>
          <div onClick={closeWindow} className={classes.okBtn}>
            Ок
          </div>
        </div>
      </section>
    </div>
  )
}
