import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ESort,
  TFilterByGenresValue,
  TFilterValue,
  TGenreItem,
  TPhonogram,
  TPhonogramsState,
  TPhonogramStatistics,
} from 'store/modules/phonogramms/types'
import { LIMIT } from 'api/const'

const initialState: TPhonogramsState = {
  rowIndex: 0,
  firstUpdate: true,
  phonogramID: null,
  phonogramsList: [],
  phonogramsListHasMore: true,
  phonogramsListForPlaylists: [],
  phonogramsListHasMoreForPlaylists: true,
  phonogramByID: null,
  phonogramByIDIsLoad: false,
  editPhonogramByIDSuccess: false,
  createPhonogramSuccess: false,
  phonogramsLoadOffset: LIMIT,
  phonogramsLoadOffsetForPlaylists: LIMIT,
  showValidateFileNameWindow: false,
  nextCatalogPhonogramNumber: null,
  genresList: [],
  navbarWidth: 240,
  buildingKaraokeFile: false,
  karaokeFileIsBuild: false,
  buildingKaraokeFileId: null,
  tempLinkBuildKaraokeFile: null,
  fileDeleting: false,
  fileDeletingSuccess: false,
  phonogramStatistics: null,
  genreSortParams: { label: 'Все жанры', value: undefined },
  searchParams: '',
  deletedPhonogramsFilterValue: { label: 'Все', value: undefined },
  approvedFilterValue: { label: 'Все', value: undefined },
  hasMusicFileFilterValue: { label: 'Все', value: undefined },
  hasLyricsFileFilterValue: { label: 'Все', value: undefined },
  draftFilterValue: { label: 'Все', value: undefined },
  orderByColumn: 'catalogPhonogramNumber',
  orderBy: ESort.ASC,
  runFilters: false,
}

const userSlice = createSlice({
  name: 'phonograms',
  initialState,
  reducers: {
    setRowIndex(state: TPhonogramsState, action: PayloadAction<number>) {
      state.rowIndex = action.payload
    },
    setFirstUpdate(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.firstUpdate = action.payload
    },
    setPhonogramID(state: TPhonogramsState, action: PayloadAction<string | null>) {
      state.phonogramID = action.payload
    },
    setPhonogramsList(state: TPhonogramsState, action: PayloadAction<Array<TPhonogram>>) {
      state.phonogramsList = action.payload
    },
    setPhonogramsListOnScroll(state: TPhonogramsState, action: PayloadAction<Array<TPhonogram>>) {
      state.phonogramsList = [...state.phonogramsList, ...action.payload]
    },
    replacePhonogramInPhonogramList(state: TPhonogramsState, action: PayloadAction<TPhonogram>) {
      state.phonogramsList = state.phonogramsList.map((p) => {
        if (p.id === action.payload.id) {
          return action.payload
        }
        return p
      })
    },
    setPhonogramsLoadOffset(state: TPhonogramsState, action: PayloadAction<number>) {
      state.phonogramsLoadOffset = action.payload
    },
    setPhonogramsListHasMore(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.phonogramsListHasMore = action.payload
    },
    setPhonogramByID(state: TPhonogramsState, action: PayloadAction<TPhonogram | null>) {
      state.phonogramByID = action.payload
    },
    setPhonogramByIDIsLoad(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.phonogramByIDIsLoad = action.payload
    },
    setEditPhonogramByIDSuccess(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.editPhonogramByIDSuccess = action.payload
    },
    setCreatePhonogramSuccess(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.createPhonogramSuccess = action.payload
    },
    setShowValidateFileNameWindow(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.showValidateFileNameWindow = action.payload
    },
    setNextCatalogPhonogramNumber(state: TPhonogramsState, action: PayloadAction<number | null>) {
      state.nextCatalogPhonogramNumber = action.payload
    },
    setGenreList(state: TPhonogramsState, action: PayloadAction<TGenreItem[]>) {
      state.genresList = action.payload
    },
    setNavbarWidth(state: TPhonogramsState, action: PayloadAction<number>) {
      state.navbarWidth = action.payload
    },
    setBuildingKaraokeFile(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.buildingKaraokeFile = action.payload
    },
    setKaraokeFileIsBuild(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.karaokeFileIsBuild = action.payload
    },
    setBuildKaraokeFileId(state: TPhonogramsState, action: PayloadAction<string | null>) {
      state.buildingKaraokeFileId = action.payload
    },
    setTempLinkBuildKaraokeFile(state: TPhonogramsState, action: PayloadAction<string | null>) {
      state.tempLinkBuildKaraokeFile = action.payload
    },
    setFileDeleting(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.fileDeleting = action.payload
    },
    setFileDeletingSuccess(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.fileDeletingSuccess = action.payload
    },
    setPhonogramStatistics(state: TPhonogramsState, action: PayloadAction<TPhonogramStatistics | null>) {
      state.phonogramStatistics = action.payload
    },
    setGenreSortParams(state: TPhonogramsState, action: PayloadAction<TFilterByGenresValue>) {
      state.genreSortParams = action.payload
    },
    setSearchParams(state: TPhonogramsState, action: PayloadAction<string>) {
      state.searchParams = action.payload
    },
    setDeletedPhonogramsFilterValue(state: TPhonogramsState, action: PayloadAction<TFilterValue>) {
      state.deletedPhonogramsFilterValue = action.payload
    },
    setApprovedFilterValue(state: TPhonogramsState, action: PayloadAction<TFilterValue>) {
      state.approvedFilterValue = action.payload
    },
    setHasMusicFileFilterValue(state: TPhonogramsState, action: PayloadAction<TFilterValue>) {
      state.hasMusicFileFilterValue = action.payload
    },
    setHasLyricsFileFilterValue(state: TPhonogramsState, action: PayloadAction<TFilterValue>) {
      state.hasLyricsFileFilterValue = action.payload
    },
    setDraftFilterValue(state: TPhonogramsState, action: PayloadAction<TFilterValue>) {
      state.draftFilterValue = action.payload
    },
    setOrderByColumn(state: TPhonogramsState, action: PayloadAction<string>) {
      state.orderByColumn = action.payload
    },
    setOrderBy(state: TPhonogramsState, action: PayloadAction<ESort>) {
      state.orderBy = action.payload
    },
    setRunFilters(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.runFilters = action.payload
    },

    setPhonogramsListForPlaylists(state: TPhonogramsState, action: PayloadAction<Array<TPhonogram>>) {
      state.phonogramsListForPlaylists = action.payload
    },
    setPhonogramsListOnScrollForPlaylists(state: TPhonogramsState, action: PayloadAction<Array<TPhonogram>>) {
      state.phonogramsListForPlaylists = [...state.phonogramsListForPlaylists, ...action.payload]
    },
    setPhonogramsLoadOffsetForPlaylists(state: TPhonogramsState, action: PayloadAction<number>) {
      state.phonogramsLoadOffsetForPlaylists = action.payload
    },
    setPhonogramsListHasMoreForPlaylists(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.phonogramsListHasMoreForPlaylists = action.payload
    },
  },
})

export const {
  setPhonogramsList,
  setPhonogramsListOnScroll,
  setPhonogramsLoadOffset,
  setPhonogramsListHasMore,
  setPhonogramByID,
  setPhonogramID,
  setPhonogramByIDIsLoad,
  setEditPhonogramByIDSuccess,
  setCreatePhonogramSuccess,
  setShowValidateFileNameWindow,
  setNextCatalogPhonogramNumber,
  setGenreList,
  setNavbarWidth,
  setBuildingKaraokeFile,
  setKaraokeFileIsBuild,
  setBuildKaraokeFileId,
  setTempLinkBuildKaraokeFile,
  setFileDeleting,
  setFileDeletingSuccess,
  setPhonogramStatistics,
  setGenreSortParams,
  setSearchParams,
  setDeletedPhonogramsFilterValue,
  setApprovedFilterValue,
  setHasMusicFileFilterValue,
  setHasLyricsFileFilterValue,
  setDraftFilterValue,
  setOrderByColumn,
  setOrderBy,
  setRunFilters,
  setFirstUpdate,
  setRowIndex,
  replacePhonogramInPhonogramList,

  setPhonogramsListForPlaylists,
  setPhonogramsListOnScrollForPlaylists,
  setPhonogramsLoadOffsetForPlaylists,
  setPhonogramsListHasMoreForPlaylists,
} = userSlice.actions

export default userSlice.reducer
