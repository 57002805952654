import React, { FC } from 'react'
import { ReactComponent as IconPhonograms } from 'assets/icons/iconPhonograms.svg'
import { ReactComponent as IconPlaylists } from 'assets/icons/iconPlaylists.svg'
import { ReactComponent as IconCharts } from 'assets/icons/iconCharts.svg'
import { ReactComponent as IconClients } from 'assets/icons/iconClients.svg'
import { ReactComponent as IconStatistics } from 'assets/icons/iconStatistics.svg'
import { ReactComponent as IconUsers } from 'assets/icons/iconUsers.svg'
import { ReactComponent as IconTariff } from 'assets/icons/iconTariff.svg'
import { ReactComponent as IconImages } from 'assets/icons/iconImages.svg'
import { Routes } from 'routes/routes'
import { NavItem } from 'pages/main/sideBar/navigation/navItem'

export const Navigation: FC = () => {
  const linksArray = [
    { title: 'Фонограммы', to: Routes.home, icon: IconPhonograms },
    { title: 'Плейлисты', to: Routes.playlists, icon: IconPlaylists },
    { title: 'Чарты', to: Routes.charts, icon: IconCharts },
    { title: 'Клиенты', to: Routes.clients, icon: IconClients },
    { title: 'Статистика', to: Routes.statistics, icon: IconStatistics },
    { title: 'Тарифы', to: Routes.tariffs, icon: IconTariff },
    { title: 'Изображения', to: Routes.images, icon: IconImages },
    { title: 'Пользователи', to: Routes.usersAndRoles, icon: IconUsers },
  ]

  return (
    <section>
      {linksArray.map((link, index) => (
        <NavItem key={index} {...link} />
      ))}
    </section>
  )
}
