import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TClientInList, TClientsState, TDevice, TClient, TPayment, TRoom } from 'store/modules/clients/types'

const initialState: TClientsState = {
  clientById: null,
  clientsList: [],
  devicesList: [],
  clientPaymentHistory: [],
  spotRooms: [],
}

const userSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setClientById(state: TClientsState, action: PayloadAction<TClient | null>) {
      state.clientById = action.payload
    },
    setClientList(state: TClientsState, action: PayloadAction<Array<TClientInList>>) {
      state.clientsList = action.payload
    },
    setDevicesList(state: TClientsState, action: PayloadAction<Array<TDevice>>) {
      state.devicesList = action.payload
    },
    setSpotsRoomsList(state: TClientsState, action: PayloadAction<Array<TRoom>>) {
      state.spotRooms = action.payload
    },
    setClientPaymentHistory(state: TClientsState, action: PayloadAction<TPayment[]>) {
      state.clientPaymentHistory = action.payload
    },
  },
})

export const {
  setClientList,
  setClientById,
  setDevicesList,
  setClientPaymentHistory,
  setSpotsRoomsList,
} = userSlice.actions

export default userSlice.reducer
