import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TPublicTariffRegion, TTariff, TTariffsState } from 'store/modules/tariffs/types'

const initialState: TTariffsState = {
  publicTariffShowPopUp: false,
  publicTariffRegion: null,
  publicTariffRegionId: null,
  publicTariffRegions: [],
  tariffs: [],
  tariff: null,
}

const userSlice = createSlice({
  name: 'tariffs',
  initialState,
  reducers: {
    setPublicTariffShowPopUp(state: TTariffsState, action: PayloadAction<boolean>) {
      state.publicTariffShowPopUp = action.payload
    },
    setPublicTariffRegion(state: TTariffsState, action: PayloadAction<TPublicTariffRegion | null>) {
      state.publicTariffRegion = action.payload
    },
    setPublicTariffRegionId(state: TTariffsState, action: PayloadAction<string | null>) {
      state.publicTariffRegionId = action.payload
    },
    setPublicTariffRegions(state: TTariffsState, action: PayloadAction<TPublicTariffRegion[]>) {
      state.publicTariffRegions = action.payload
    },
    setTariffs(state: TTariffsState, action: PayloadAction<TTariff[]>) {
      state.tariffs = action.payload
    },
    setTariff(state: TTariffsState, action: PayloadAction<TTariff | null>) {
      state.tariff = action.payload
    },
  },
})

export const {
  setPublicTariffShowPopUp,
  setPublicTariffRegion,
  setPublicTariffRegionId,
  setPublicTariffRegions,
  setTariffs,
  setTariff,
} = userSlice.actions

export default userSlice.reducer
