import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TBackgroundImage, TCategory, TImagesState, TTag } from 'store/modules/images/types'
import { DEFAULT_SUBTITLES } from 'constants/subtitles'

const initialState: TImagesState = {
  subtitles: DEFAULT_SUBTITLES,
  images: [],
  tags: [],
  filteringTags: [],
  openImage: {
    withEditBlock: false,
    imageId: null,
  },
  categories: [],
  image: null,
  isLoading: false,
}

const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    setSubtitles(state: TImagesState, action: PayloadAction<string>) {
      state.subtitles = action.payload
    },
    setTags(state: TImagesState, action: PayloadAction<TTag[]>) {
      state.tags = action.payload
    },
    setFilteringTags(state: TImagesState, action: PayloadAction<TTag[]>) {
      state.filteringTags = action.payload
    },
    openImage(
      state: TImagesState,
      action: PayloadAction<{
        withEditBlock: boolean
        imageId: string | null
      }>,
    ) {
      state.openImage = action.payload
    },
    setImages(state: TImagesState, action: PayloadAction<TBackgroundImage[]>) {
      state.images = action.payload
    },
    setImage(state: TImagesState, action: PayloadAction<TBackgroundImage | null>) {
      state.image = action.payload
    },
    setCategories(state: TImagesState, action: PayloadAction<TCategory[]>) {
      state.categories = action.payload
    },
    setIsLoading(state: TImagesState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
  },
})

export const {
  setSubtitles,
  setTags,
  setFilteringTags,
  openImage,
  setImages,
  setCategories,
  setImage,
  setIsLoading,
} = imagesSlice.actions

export default imagesSlice.reducer
