import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TFileState } from 'store/modules/file/types'
import { TError$ } from 'store/modules/error/types'

const initialState: TFileState = {
  xlsFileID: null,
  xlsFileOriginalName: '',
  showParsingWindow: false,
  fileUpLoading: false,
  xlsFileParsing: false,
  karaokeFilesUploading: false,
  karaokeFilesCount: null,
  karaokeFilesTotalCount: null,
  xlsFileParsingError: null,
}

const userSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    setXlsFileParsingError(state: TFileState, action: PayloadAction<TError$ | null>) {
      state.xlsFileParsingError = action.payload
    },
    setFileUpLoading(state: TFileState, action: PayloadAction<boolean>) {
      state.fileUpLoading = action.payload
    },
    setXlsFileID(state: TFileState, action: PayloadAction<number | null>) {
      state.xlsFileID = action.payload
    },
    setXlsFileOriginalName(state: TFileState, action: PayloadAction<string>) {
      state.xlsFileOriginalName = action.payload
    },
    setShowParsingWindow(state: TFileState, action: PayloadAction<boolean>) {
      state.showParsingWindow = action.payload
    },
    setXlsFileParsing(state: TFileState, action: PayloadAction<boolean>) {
      state.xlsFileParsing = action.payload
    },
    setKaraokeFilesUploading(state: TFileState, action: PayloadAction<boolean>) {
      state.karaokeFilesUploading = action.payload
    },
    setKaraokeFilesCount(state: TFileState, action: PayloadAction<number | null>) {
      state.karaokeFilesCount = action.payload
    },
    setKaraokeFilesTotalCount(state: TFileState, action: PayloadAction<number | null>) {
      state.karaokeFilesTotalCount = action.payload
    },
  },
})

export const {
  setXlsFileID,
  setXlsFileOriginalName,
  setShowParsingWindow,
  setFileUpLoading,
  setXlsFileParsing,
  setKaraokeFilesUploading,
  setKaraokeFilesCount,
  setKaraokeFilesTotalCount,
  setXlsFileParsingError,
} = userSlice.actions
export default userSlice.reducer
